import React from 'react';
import { useState, useRef, useEffect, useContext } from 'react';
import { FormContext } from '../formContext';

export default function FileList({ item, list }) {

    const { updateFormData, errorText } = useContext(FormContext);
    const [files, setFiles] = useState();
    const fileRef = useRef('');

    useEffect(() => {
        if (list !== null && list !== undefined) {
            const dt = new DataTransfer();
            Array.from(list).forEach(file => dt.items.add(file));
            setFiles(dt.files);
            fileRef.current.files = dt.files;
            // console.log(item.label, dt.files);
        }
    }, [])


    function handleFileUpload(event) {
        let fileList = event.target.files;
        setFiles(fileList);
        if (event.target.required === true && event.target.checkValidity()) {
            document.getElementById(`error-message-${event.target.name}`).classList.add('no-display');
        }
        updateFormData(event, item.label, item.name, event.target.files);
    }

    const handleDelete = (event, name) => {
        event.preventDefault();
        let newFileList = Array.from(files).filter(file => file.name !== name);
        setFiles(newFileList);
        const dt = new DataTransfer();
        newFileList.map((file) => dt.items.add(file));
        fileRef.current.files = dt.files;
        updateFormData("", item.label, item.name, dt.files);
    }

    const handleDrop = (event) => {
        event.preventDefault();
        const dropedFiles = event.dataTransfer.files;
        setFiles(dropedFiles);

        const dt = new DataTransfer();
        [...dropedFiles].map((file) => dt.items.add(file));
        fileRef.current.files = dt.files;

        const fileElement = event.target.parentElement.querySelector('input[type=file]');
        if (fileElement.required === true && fileElement.checkValidity()) {
            document.getElementById(`error-message-${event.target.name}`).classList.add('no-display');
        }
        updateFormData(event, item.label, item.name, event.dataTransfer.files);
    }

    const handleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <>
            {item && <div className={`${item.show === "false" ? "no-display" : ""}`}>
                <p className={`question-header question-header__extra-space ${item.required === "true" ? "required" : ""}`}>{item.label}</p>
                <label className="file-label">
                    <input name={item.name} required={item.required === "true" ? true : false} ref={fileRef} type="file" onClick={(event) => { event.target.value = "" }} onChange={handleFileUpload} multiple size="20mb" />
                    <span className="file-label__symbol" onDrop={handleDrop} onDragOver={handleDrag} >{item.text}</span>
                </label>
                {item.required === "true" && <p id={`error-message-${item.name}`} className="error-message no-display">{item.error ? item.error : errorText}</p>}
                {files && <ul className="file-list" aria-live="polite" aria-label="liste av fil/filer">
                    {Array.from(files).map((file, index) => {
                        return (
                            < li className="file-del-buttton" key={index}  > {file.name} < button type="button" aria-label="Slett denne fil / delete this file" onClick={(event) => { handleDelete(event, file.name) }}> <span aria-hidden="true">&times;</span></button ></li >
                        )
                    })}
                </ul >}
            </div>
            }
        </>
    )
}
