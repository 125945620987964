import React, { useEffect, useContext } from 'react';
import { FormContext } from './formContext';
import Field from '../components/custom/Field';

export default function Home() {
    const { formText, formData, updateStage, tokenError, showSpinner } = useContext(FormContext);
    const { requirement: data } = formText;

    useEffect(() => {
        updateStage(0);
    })

    return (
        <>
            {showSpinner && <div className="spinner">
                <div className="spinner__content" role="alert">
                    <span aria-hidden="true" className="spinner__animation"></span>
                    <h5 className="small-header">{formText["waiting_policy"]}</h5>
                </div>
            </div>
            }
            <h3 className='medium-header'>{data["title"]}</h3>
            <p>{data["information"]}</p>
            <Field item={{ data: data["polisnumber"], key: "polisnumber", type: "text", value: formData[data["polisnumber"]["name"]], pattern: "\\d*" }} />
            <Field item={{ data: data["postnumber"], key: "postnumber", type: "text", value: formData[data["postnumber"]["name"]], pattern: "[0-9]{4}$" }} />
            {tokenError && <p className="error-message">{formText["token_error"]}</p>}
        </>
    )
}
