

export async function validatePolicy(policyNumber, postNumber) {
    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/validation`, {
        'method': 'Post',
        body: JSON.stringify({ Polisenummer: policyNumber, Postnummer: postNumber }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const data = await response.json()
    return data
}


export async function allianzTabel(token, body) {

    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/allianztabel/${encodeURIComponent(token)}`, {
        'method': 'Post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    const data = await response.json()
    return data
}

export async function postData(token, body) {

    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/maildata/${encodeURIComponent(token)}`, {
        'method': 'Post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    const data = await response.json()
    return data
}

// Post attachment
export async function postAttchment(token, attachment) {

    let formData = new FormData();

    formData.append("attachment", attachment, attachment.name)

    return fetch(`${process.env.REACT_APP_API_SERVICE}/Attachment/${encodeURIComponent(token)}`, {
        'method': 'Post',
        body: formData

    }).then(response => response.json()).then(data => { return data })

}

