import React, { useEffect, useContext } from 'react';
import { FormContext } from './formContext';
import { Navigate } from 'react-router-dom';

export default function Receipt() {

    const { formText, updateStage, language, allianzCase, policyDataNorwegian, policyDataEnglish, insuredDataNorwegian, insuredDataEnglish, damageDataNorwegian, damageDataEnglish, deviationDataNorwegian, deviationDataEnglish, errorText, updateFormData } = useContext(FormContext);
    const { receipt: data } = formText;
    
    useEffect(() => {
        if(!formText){
            updateStage(0);
            Navigate("/");
        }
        const currentStage = allianzCase ? 3 : 4;
        updateStage(currentStage);
    },[])

    const ItemPolicy = () => {
        let result = language === "No" ? policyDataNorwegian : policyDataEnglish;
        if(!result) return(<></>);
        result = Object.entries(result);
        return (
            <div>
                {result && result.map((item, index) => {
                    let name = item[0];
                    let value = item[1];
                    return (
                        <p key={index}><strong>{name}:</strong> {value}</p>
                    )
                })}
            </div>
        )
    }
    const ItemInsured = () => {
        let result = language === "No" ? insuredDataNorwegian : insuredDataEnglish;
        if(!result) return(<></>);
        result = Object.entries(result);
        return (
            <div>
                {result && result.map((item, index) => {
                    let name = item[0];
                    let value = item[1];
                    return (
                        <p key={index}><strong>{name}:</strong> {value}</p>
                    )
                })}
            </div>
        )
    }
    const ItemDamage = () => {
        let result = language === "No" ? damageDataNorwegian : damageDataEnglish;
        let deviation = language === "No" ? deviationDataNorwegian : deviationDataEnglish;
        let deviationKey = Object.keys(deviation);
        result = Object.entries(result);
        return (
            <div>
                <div>
                    {result && result.map((item, index) => {
                        let name = item[0];
                        let value = item[1];
                        return (
                            <p key={index}><strong>{name}:</strong> {value}</p>
                        )
                    })}
                </div>
                <div>
                    {deviation && deviationKey.map((item, index) => {
                        let list = Object.entries(deviation[item])
                        return (
                            <div key={index}>
                                <h5>{data["deviation_title"]} {index + 1}</h5>
                                <DeviationItem list={list} />
                            </div>
                        )
                    })}
                </div>
            </div >

        )
    }

    const DeviationItem = ({ list }) => {
        return (
            <div >
                {list.map((item, index) => {
                    let name = item[0];
                    let value = item[1];
                    return (
                        <p key={index}><strong>{name}:</strong> {value}</p>
                    )
                })}
            </div>
        )
    }

    return (
        <>

            <h3 className='medium-header'>{data["title"]}</h3>
            <p className='lead'>{data["text_lead"]}</p>
            <p >{data["subtext"]}</p>

            <div id='htmlWrapper'>
                <h4>{data["information_title"]}</h4>
                <ItemPolicy />
                <h4>{data["insured_title"]}</h4>
                <ItemInsured />
                <h4>{data["damage_title"]}</h4>
                <ItemDamage />
            </div>

            <div>
                <label className="checkbox-label">
                    <input type="checkbox" name="bekreftelse" onChange={event => { updateFormData(event) }} required />
                    <span className="checkbox-symbol">{data["consent"]}</span>
                </label>
                <p id="error-message-bekreftelse" className="error-message no-display">{errorText}</p>
                <label className="checkbox-label">
                    <input type="checkbox" name="salesBekreftelse" onChange={event => { updateFormData(event) }} required />
                    <span className="checkbox-symbol">{data["confirm"]}</span>
                </label>
                <p id="error-message-salesBekreftelse" className="error-message no-display">{errorText}</p>
            </div>


        </>
    )
}

