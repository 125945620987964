export default function englishSource() {
    return (
        {
            routes: [{ path: "/", title: "The claim" }, { path: "/forsikrede", title: "The insured" }, { path: "/meldingtype", title: "Type of report" }, { path: "/kjoperforsikring", title: "Damage report" }, { path: "/dokument", title: "Documents" }, { path: "/oppdatering", title: "Update" }, { path: "/kvittering", title: "Receipt" }],
            waiting: "Please wait...",
            waiting_policy: "Validating policy number",
            token_error: "We could not find relevant case based on the information that you have given.",
            completed_message: "Your case has now registered with registeration number: ",
            form_fail: "An error has occured!",

            requirement: {
                title: "You can register your claim here",
                information: "Fill in your policy number and the postal code of the acquired property",
                polisnumber: {
                    label: "Policy number",
                    name: "polisenummer",
                    placeholder: "",
                    required: "true",
                    error: "This field is required (only digits)"
                },
                postnumber: {
                    label: "Postal code",
                    name: "postnummer",
                    placeholder: "",
                    required: "true",
                    error: "This field is required (4 digits)"
                },
                is_dnb: {
                    label: "Have you bought your property via DNB?",
                    values: ["Ja", "Nei"],
                    labels: ["Yes", "No"],
                    required: "",
                    error: ""
                },
                not_dnb: {
                    text: "We can not proceed with your case if you did not buy it via DNB. You may contact your agent or Söderberg&Partners for more information.",
                    header: "Contact information for Söderberg&Partners:",
                    email: {
                        label: "E-mail",
                        value: "boligkjoperforsikring@soderbergpartners.no"
                    },
                    tel: {
                        label: "phone",
                        value: "930 00 020"
                    }
                }
            },
            insured: {
                title: "Insured",
                fullname: {
                    label: "Full name",
                    name: "fulltnavn",
                    placeholder: "Full navn",
                    required: "true",
                    error: ""
                },
                address: {
                    label: "Address of the acquired property",
                    name: "addresse",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                place: {
                    label: "Postal code and area",
                    name: "sted",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                email: {
                    label: "E-mail",
                    name: "epost",
                    placeholder: "",
                    required: "true",
                    error: ""
                },
                phone: {
                    label: "Phone number",
                    name: "telefonnummer",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                upload_report: {
                    label: "Would you like to report deviations or upload dokument?",
                    values: ["Meld avvik", "last opp dokumenter"],
                    labels: ["Report deviations", "Upload document"],
                    required: "true",
                    error: ""
                },
            },
            information: {

                title: "Purchase information",
                sellername: {
                    label: "Name of seller",
                    name: "NavnPaaSelger",
                    placeholder: "Full navn",
                    error: ""
                },
                purchase_price: {
                    label: "Purchase price",
                    name: "Kjoepesum",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                date_for_contract: {
                    label: "Contract signing date",
                    name: "DatoForKontraktsignering",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                date_for_takeover: {
                    label: "Take over date",
                    name: "DatoForOvertakelse",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                bulding_area: {
                    label: "Year of construction",
                    name: "byggeaar",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                on_property_visit: {
                    label: "Did you attend a viewing?",
                    values: ["Ja", "Nei"],
                    labels: ["Yes", "No"],
                    required: "",
                    error: ""
                },
                deviation_visible: {
                    label: "Were the deviations visible during the viewing?",
                    values: ["Ja", "Nei"],
                    labels: ["Yes", "No"],
                    required: "",
                    error: ""
                },
                deviation_further_examine: {
                    label: "Did you do any further examination of the deviations?",
                    values: ["Ja", "Nei"],
                    labels: ["Yes", "No"],
                    required: "",
                    error: ""
                },
                work_with_building: {
                    label: "Do you or others that came with you to the viewing work with building construction?",
                    values: ["Ja", "Nei"],
                    labels: ["Yes", "No"],
                    required: "",
                    error: ""
                },
                agent_professional_background: {
                    label: "Who was with you on the viewing and what is their professional background?",
                    name: "HvemVarMedPaVisningOgHvaErDeresYrkesbakgrunn",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                signed_owner_shift: {
                    label: "Did the seller buy home sellers insurance?",
                    values: ["Ja", "Nei"],
                    labels: ["Yes", "No"],
                    required: "",
                    error: ""
                },
                second_title: "Information about the deviations",
                deviation: {
                    header: "How many deviations do you want to report?",
                    information: "Select deviation to fill in information",
                    text: "Deviation"
                },
                damage_type: {
                    label: "Type of damage",
                    name: "skadetype",
                    required: "",
                    initial_value: "--- Select deviation ---",
                    values: ["Pests", "Grey Silverfish", "Leakage/moisture", "Mildew/rot", "Bathroom", "Windows", "Roof", "Construction error", "Area variance", "Illegality/decree", "incorrect information about costs/expenses", "Electrical system", "Exterior/plot", "Other"]
                },
                deviation_description: {
                    label: "Describe deviation",
                    name: "BeskrivelseAvAvviket",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                deviation_information: {
                    label: "Have you spoken to the seller or the realtor about the deviations? If so, what information did you receive?",
                    name: "InformasjonOmAvvikFraSelger",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                deviation_informed: {
                    label: "Were you informed about the deviations before bidding? If so, what information did you receive?",
                    name: "InformasjonFoerBindendeBud",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                when_deviation_detected: {
                    label: "When did you discover the deviation?",
                    name: "NaarOppdagetDuAvviket",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                how_deviation_detected: {
                    label: "How did you discover the deviations? Please elaborate",
                    name: "HvordanOppdagetDuAvviket",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                self_reclaim: {
                    label: "Have you given the seller, directly or by way of realtor, written notice about the deviations?",
                    values: ["Yes", "No"],
                    labels: ["Ja", "Nei"],
                    required: "",
                    error: ""
                },
                information_sent: {
                    label: "When, and what information did you send?",
                    name: "NaarOgHvilkenInformasjonKomFremVedSelvReklamasjon",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                deviation_sale_document: {
                    label: "Are the deviations mentioned in the sales documentation?",
                    name: "ErAvvikNevntIDokumentasjonen",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                incorrect_information: {
                    label: "In your opinion, has the seller given incomplete or incorrect information about the deviations?",
                    values: ["Ja", "Nei"],
                    labels: ["Yes", "No"],
                    required: "",
                    error: ""
                },
                why_mentioned: {
                    label: "I så fall, hvorfor mener du dette?",
                    name: "HvorforMenerDuSelgerHarGittUriktigInformasjon",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                assumed_repair_cost: {
                    label: "Are you able to estimate the cost of repair?",
                    name: "AntattUtbedringsKostnad",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                contact_house_insurance: {
                    label: "Have you been in contact with the building insurer? If so, what information did they give you?",
                    name: "KontaktMedHusOgInnboforsikring",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                additional_information: {
                    label: "Any additional comments about the deviations or the general state of the building",
                    name: "TilleggsKommentar",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                attachment_title: "Necessary documentation",
                Other_document: {
                    label: "Other document",
                    text: "Select file",
                    name: "andreDokument",
                    required: "",
                    error: "",
                },
                picture: {
                    label: "Picture",
                    text: "Select file",
                    name: "bilde",
                    required: "",
                    error: "",
                },
                sales_task: {
                    label: "Sales prospect",
                    text: "Select file",
                    name: "salgsoppgave",
                    required: "",
                    error: "",
                },
                purchasing_contract: {
                    label: "Contract",
                    text: "Select file",
                    name: "kjopekontrakt",
                    required: "",
                    error: "",
                },
                takeover_protocol: {
                    label: "Takeover Protocol",
                    text: "Select file",
                    name: "overtagelsesprotokoll",
                    required: "",
                    error: "",
                }
            },
            receipt: {
                title: "Summary",
                text_lead: "Please review the information you have provided before submitting your claim",
                subtext: "You will hear from us as as soon as a claims handler has reviewed your case.",
                information_title: "Submit your claim",
                insured_title: "The Insured",
                damage_title: "Damage Report",
                deviation_title: "Deviation",
                consent: "By submitting this form you agree that all future communication will be done electronically.",
                confirm: "I confirm that I have read through the sales documentation and the deviations I report are not in connection with the sale."
            },
            form_navigation: {
                next: "Next",
                back: "Back",
                submit: "Submit",
                error: "All required field has to be answered!"
            },
            footer: {
                information_1: "Informasjon om saksgangen",
                information_2: "Informasjon om Avhendingsloven",
                privacy: "Privacy declaration",
                phone: "Phone",
                contact_form_title: "Contact form",
                contact_form_body: "Go to contact form",
                helpText: "For any inquiry please contact us by phone monday to friday between 0800 and 1600. Tlf: "
            }
        }

    )
}

