import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FormContext } from './formContext';


export default function InformationForCases() {

    const { updateNavigationVisiblity, stage, routes } = useContext(FormContext);
    const link = routes[stage].path

    useEffect(() => {
        updateNavigationVisiblity(false)
        document.body.scrollTop = document.documentElement.scrollTop = 0

    }, [])

    return (
        <div className='information-container'>
            <h3 className='medium-header'>Generell informasjon om saksgangen</h3>
            <p className='arrow lead'>
                Du melder inn saken til oss og får et saksnummer
            </p>

            <p className='arrow lead'>
                Når saken din er registret hos oss, reklamerer vi til selger/ selgers boligselgerforsikring
                for å avbryte reklamasjonsfrister
            </p>

            <p className='arrow lead'>
                Når vi har mottatt all nødvendig dokumentasjon (salgsoppgave, takst, egenerklæring, signert kjøpekontrakt, overtakelsesprotokoll) vil du få en saksbehandler
            </p>

            <p className='arrow lead'>
                Saksbehandler gjennomgår forholdene det reklameres over, og foretar en innledende juridisk vurdering av saken
            </p>

            <p className='lead'>
                Saksbehandler gir deg en skriftlig tilbakemelding med den juridiske vurderingen av saken.
                Tilbakemelding vil resultere i ett av følgende:
            </p>

            <ul class="dottedlist">
                <li>Vi vurderer det som sannsynlige at det kan foreligge en rettslig mangel etter avhendingsloven. Vi vil be om at egenandel på kr. 4000,- innbetales.</li>
                <li>Vi mener det er nødvendig at du innhenter ytterligere dokumentasjon/ informasjon for å avklare forholdene nærmere før vi tar en endelig vurdering.</li>
                <li>Vi vurderer det som at forholdet ikke er en rettslig mangel, og kan ikke anbefale at saken forfølges videre mot selger. </li>
            </ul>

            <Link className='link-back' to={link} >Tilbake til webskjema</Link>
        </div >
    )
}
