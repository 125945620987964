import React from 'react';

export default function Logo({ text }) {
    return (
        <div className="form-container response-wrapper" aria-live="assertive">
            <img src="logo.png" alt="logo" />
            <h4 className="normal-header">{text}</h4>
        </div>
    )
}




