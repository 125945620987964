import React, { useEffect, useContext } from 'react';
import { FormContext } from './formContext';
import UploadDocument from './UploadDocument';
import DamageReport from './DamageReport';


export default function DamageUploadReport() {

    const { formData, updateStage } = useContext(FormContext);

    useEffect(() => {
        updateStage(2);
    })

    return (
        <>
            {
                formData["reportOrUpload"] === "Meld avvik" ? <DamageReport /> : <UploadDocument />
            }
        </>
    )
}