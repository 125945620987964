import React, { useContext, useEffect } from 'react'
import { FormContext } from './formContext';
import FileList from '../components/custom/FileList';
import { Navigate } from 'react-router-dom';


export default function UploadDocument() {
    const { formText, allianzCase, formFiles, updateStage } = useContext(FormContext);
    const { information: data } = formText;

    useEffect(() => {
        if(!formText) {
            updateStage(0);
            Navigate("/");
        }
        const currentStage = allianzCase ? 2 : 3;
        updateStage(currentStage);
    },[])

    return (
        <>
            <h3 className='medium-header'>{data["attachment_title"]}</h3>

            <FileList item={{ text: data["sales_task"]["text"], label: data["sales_task"]["label"], name: "salgsoppgave", required: false, multiple: true }} list={formFiles && formFiles["salgsoppgave"]} />
            <FileList item={{ text: data["purchasing_contract"]["text"], label: data["purchasing_contract"]["label"], name: "kjopekontrakt", required: false, multiple: true }} list={formFiles["kjopekontrakt"]} />
            <FileList item={{ text: data["takeover_protocol"]["text"], label: data["takeover_protocol"]["label"], name: "overtagelsesprotokoll", required: false, multiple: true }} list={formFiles["overtagelsesprotokoll"]} />
            <FileList item={{ text: data["picture"]["text"], label: data["picture"]["label"], name: "bilde", required: allianzCase ? "true" : "false", multiple: true }} list={formFiles && formFiles["bilde"]} />
        </>
    )
}