import './App.scss';
// import './js/chat';
import { useState, useRef, useEffect } from 'react';
import { FormContext } from './components/formContext';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import FormNavigation from './components/FormNavigation';
import Logo from './components/Logo';
import Home from './components/Home';
import Insured from './components/Insured';
import DamageReport from './components/DamageReport.js';
import Receipt from './components/Receipt';
import norwegianSource from '../src/data/norwegian-source';
import englishSource from '../src/data/english-source.js';
import getEmailBody from '../src/data/emailBody';
import InformationForProcessing from './components/InformationForProcessing';
import InformationForCases from './components/InformationForCases';
import { validatePolicy, allianzTabel, postData, postAttchment } from './js/api-service';
import ReportUpload from './components/ReportUpload.js';
import UploadDocument from './components/UploadDocument.js';
import DamageUploadReport from './components/DamageUploadReport.js';


function App() {

  const [formValid, setFormValid] = useState(false);
  const [stage, setStage] = useState(0);
  const [formData, setformData] = useState('');
  const [formDataPartial, setFormDataPartial] = useState('');
  const [policyDataNorwegian, setPolicyDataNorwegian] = useState();
  const [policyDataEnglish, setPolicyDataEnglish] = useState();
  const [insuredDataNorwegian, setInsuredDataNorwegian] = useState();
  const [insuredDataEnglish, setInsuredDataEnglish] = useState();
  const [deviationNumber, setDeviationNumber] = useState(1);
  const [currentPanel, setCurrentPanel] = useState(1);
  const [validPolicy, setValidPolicy] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [token, setToken] = useState();
  const [companyName, setCompanyName] = useState();
  const [policyNumber, setPolicyNumber] = useState();
  const [navigationVisiblity, setNavigationVisiblity] = useState(true);
  const [formError, setFormError] = useState(false);
  const [validStages, setValidStages] = useState();
  const [caseNumber, setCaseNumber] = useState();

  const [formText, setFormText] = useState(norwegianSource());
  const [allianzCase, setAllianzCase] = useState();
  const [formSubmitted, setFormSubmitted] = useState('');
  const [formFiles, setformFiles] = useState('');
  const [language, setLanguage] = useState('No');
  const [errorText, setErrorText] = useState();
  const [isIE, setIsIE] = useState(false);
  const [routes, setRoutes] = useState(formText["routes"]);
  const [routesPath, setRoutesPath] = useState(null);
  const [deviationData, setDeviationData] = useState('');
  const [deviationDataNorwegian, setDeviationDataNorwegian] = useState('');
  const [deviationDataEnglish, setDeviationDataEnglish] = useState('');
  const [damageData, setDamageData] = useState('');
  const [damageDataNorwegian, setDamageDataNorwegian] = useState('');
  const [damageDataEnglish, setDamageDataEnglish] = useState('');
  const [bodyFormat, setBodyFormat] = useState();
  const formElement = useRef();
  const navigate = useNavigate();
  const { requirement: data } = formText;
  const formTextNorwegian = norwegianSource();
  const formTextEnglish = englishSource();

  let keys = ["requirement", "insured", "information"];
  const navItems = document.querySelectorAll('.main-navigation li');

  const browser = window.navigator.userAgent;
  const msie_old = browser.indexOf("MSIE ");
  const msie_new = browser.indexOf('Trident/');

  if (msie_old > 0 || msie_new > 0) {
    setIsIE(true);
  }

  useEffect(() => {
    let formtext = language === "No" ? norwegianSource() : englishSource();
    setFormText(formtext);
    setRoutes(formtext["routes"]);
    if (language === "No") {
      setErrorText("Dette feltet er obligatorisk");
    } else {
      setErrorText("This field is required");
    }
    if (allianzCase) {
      const tmpRoutesPth = formtext["routes"].filter(route => route.path !== "/forsikrede" && route.path !== "/kjoperforsikring" && route.path !== "/dokument")
      setRoutesPath(tmpRoutesPth)
    } else {
      const tmpRoutesPth = formtext["routes"].filter(route => route.path !== "/meldingtype" && route.path !== "/oppdatering")
      setRoutesPath(tmpRoutesPth)
    }
  }, [language, allianzCase])

  useEffect(() => {

    updateFormValidity();
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    setFormError(false);
    if (stage !== 0 && navItems.length !== 0) {
      navItems[stage].querySelector('a').focus();
    } else {
      navigate("/")
    }

  }, [stage])

  const updateStage = (stage) => {
    setStage(stage);
    setNavigationVisiblity(true);
  }

  const updateLanguage = (language) => {
    setLanguage(language)
  }

  const handleStages = async (event, submitted = false) => {
    if (event.target.id === "nextButton" || submitted === true) {
      if (!formElement.current.checkValidity()) {
        setFormError(true);
        let list = formElement.current.querySelectorAll("[required]");
        if (list) {
          [...list].forEach(element => {
            if (!element.checkValidity()) {
              document.getElementById(`error-message-${element.name}`).classList.remove('no-display');
            }
          });
        }
      } else {
        updateFormValidity();
        setFormError(false);
        if (stage === 0) {
          setShowSpinner(true);
          setTokenError(false);
          const policynumber = formData[data["polisnumber"]["name"]].trim();
          const postnumber = formData[data["postnumber"]["name"]].trim();
          try {
            const checkpolicy = await (validatePolicy(policynumber, postnumber));
            if (checkpolicy.message === "Valid policy") {
              setPolicyNumber(policynumber);
              setBodyFormat(checkpolicy.bodyFormat)
              setToken(checkpolicy.token);
              setCompanyName(checkpolicy.name);
              setTokenError(false);
              setAllianzCase(checkpolicy.allianzCase);
              setValidPolicy(true);
              if (checkpolicy.allianzCase) {
                const tmpRoutesPth = routes.filter(route => route.path !== "/forsikrede" && route.path !== "/kjoperforsikring" && route.path !== "/dokument")
                setRoutesPath(tmpRoutesPth)
                navigate('/meldingtype');
              } else {
                const tmpRoutesPth = routes.filter(route => route.path !== "/meldingtype" && route.path !== "/oppdatering")
                setRoutesPath(tmpRoutesPth)
                navigate('/forsikrede');
              }
            } else {
              setValidPolicy(false);
              setTokenError(true);
              setPolicyNumber("");
              setToken("");
            }
            setShowSpinner(false);

          } catch (error) {
            setShowSpinner(false);
            setValidPolicy(false);
            setPolicyNumber("");
            setTokenError(true);
            setToken("");
          }
        }
      }
    } else {
      updateFormValidity();
    }

  };

  const updateFormData = (event, identifier = "", filename = false, files) => {
    if (!filename) {

      setformData({ ...formData, [event.target.name]: event.target.value });

      if (event.target.name !== "bekreftelse" && event.target.name !== "salesBekreftelse") {
        let name = event.target.name;
        let norwegianLabel = formTextNorwegian[keys[stage >= 2 ? 2 : stage]][identifier]["label"];
        let englishLabel = formTextEnglish[keys[stage >= 2 ? 2 : stage]][identifier]["label"];
        if (stage !== 2) {
          setFormDataPartial({ ...formDataPartial, [event.target.name]: event.target.value });
          if (stage === 0) {
            setPolicyDataNorwegian({ ...policyDataNorwegian, [norwegianLabel]: event.target.value });
            setPolicyDataEnglish({ ...policyDataEnglish, [englishLabel]: event.target.value });
          } else if (stage === 1) {
            setInsuredDataNorwegian({ ...insuredDataNorwegian, [norwegianLabel]: event.target.value });
            setInsuredDataEnglish({ ...insuredDataEnglish, [englishLabel]: event.target.value });
          }
        } else if (name.startsWith('avvik')) {
          let avvikname = name.split("-")[0];
          setDeviationData({ ...deviationData, [avvikname]: { ...deviationData[avvikname], [event.target.name]: event.target.value } });
          setDeviationDataNorwegian({ ...deviationDataNorwegian, [avvikname]: { ...deviationDataNorwegian[avvikname], [norwegianLabel]: event.target.value } });
          setDeviationDataEnglish({ ...deviationDataEnglish, [avvikname]: { ...deviationDataEnglish[avvikname], [englishLabel]: event.target.value } });
        } else {
          setDamageData({ ...damageData, [event.target.name]: event.target.value });
          setDamageDataNorwegian({ ...damageDataNorwegian, [norwegianLabel]: event.target.value });
          setDamageDataEnglish({ ...damageDataEnglish, [englishLabel]: event.target.value });
        }
      }

      if (event.target.checkValidity()) {
        if (event.target.required === true) {
          document.getElementById(`error-message-${event.target.name}`).classList.add('no-display');
        }
        if (event.target.type === "radio") {
          let radio = document.querySelector(`[name = ${event.target.name}]`);
          if (radio.required === true) {
            document.getElementById(`error-message-${event.target.name}`).classList.add('no-display');
          }
        }
      }
      updateFormValidity();
      if (event.target.name === "polisenummer" || event.target.name === "postnummer") {
        setValidPolicy(false)
      }

    } else {
      updateFiles(identifier, filename, files);
      updateFormValidity();
    }
  }

  const updateFiles = (label, filename, files) => {
    setformFiles({ ...formFiles, [filename]: files });
  }

  const updateFormValidity = () => {
    setFormValid(formElement.current.checkValidity());
    setValidStages({ ...validStages, [stage]: formElement.current.checkValidity() });
  }

  const ignoreTimeOffset = () => {
    const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
    const localIsoTime = (new Date(Date.now() - timeZoneOffset)).toISOString().slice(0, -1);
    return localIsoTime;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleStages(event, true);
    let validstages = Object.values(validStages)
    validstages = validstages.filter(item => item === false).length > 0;

    const currentStage = allianzCase ? 3 : 4;
    if (stage === currentStage && !validstages && formElement.current.checkValidity() && event.type === "submit") {

      setFormSubmitted("Loading");
      let fileList = formFiles;
      let countattachment = 0;
      let date = ignoreTimeOffset();
      const { insured } = formText;
      const mailfrom = formData[insured["email"]["name"]];
      const subject = formData[insured["fullname"]["name"]] + ' sak fra webskjema';
      const body = { ...policyDataNorwegian, ...insuredDataNorwegian, ...damageDataNorwegian, ...deviationDataNorwegian }
      for (const file in fileList) {
        countattachment += fileList[file].length;
      }

      const htmlWrapper = document.getElementById('htmlWrapper').outerHTML;
      const wrappedBody = htmlWrapper + JSON.stringify(formData);

      try {

        const allianzobject = {
          'Enhet': token,
          'Polisenr': policyNumber,
          'OppdragsGiver': companyName,
          'OppdragGruppe': 'BrannKombinert'
        };

        const casenumber = await allianzTabel(token, allianzobject);
        setCaseNumber(casenumber);


        const mailobject = {
          'DelegateTo': bodyFormat === 1 ? 662 : 1, //Dynamic
          'DateInserted': date,
          'DateReceived': date,
          'DateSent': date,
          'MailFrom': mailfrom,
          'MailTo': 'firmapost@crawco.no',
          'Mailsubject': subject,
          'Body': wrappedBody,
          'Direction': 'In',
          'DocType': 'Webskjema',
          'Client': companyName,
          'SystemID': 'LCS',
          'BodyFormat': bodyFormat,
          'CountAttachment': countattachment,
          'InternalComment': token,
          'Claimid': casenumber.toString()
        };


        let emailtosend;
        switch (companyName) {
          case "If Boligkjøper":
            emailtosend = "if@crawco.no";
            break;
          case "Gar-Bo":
            emailtosend = "Gar-Bo@crawco.no";
            break;
          case "Bolig2022":
            emailtosend = "Bolig2022@crawco.no";
            break;
          default:
            break;
        }

        const postdata = await postData(token, mailobject);
        if (postdata.Message === "Sent succesfully") {

          const emailBody = getEmailBody(casenumber, emailtosend, companyName);

          if (!allianzCase) {
            // mailobjectout
            const mailobjectOut = {
              'DelegateTo': 662,
              'DateInserted': date,
              'DateReceived': date,
              'MailFrom': emailtosend,
              'MailTo': mailfrom,
              'Mailsubject': `Sak registrert med saksnummer ${casenumber}`,
              'Body': emailBody,
              'Direction': 'Out',
              'DocType': 'Webskjema',
              'Client': companyName,
              'SystemID': 'LCS',
              'BodyFormat': bodyFormat,
              'CountAttachment': countattachment,
              'InternalComment': 'Kvitterings Epost',
              'Claimid': casenumber.toString()
            };

            const postdataOut = await postData(token, mailobjectOut);
            if (postdataOut.Message === "Sent succesfully") {
              let attachmentResult; // "File"
              for (const attachments in fileList) {
                if (fileList[attachments].length !== 0) {
                  let files = Array.from(fileList[attachments]);
                  for (let index = 0; index < files.length; index++) {
                    const element = files[index];
                    attachmentResult = await postAttchment(token, element);
                  }
                }
              }
              // setFormSubmitted("Submitted")
            } else {
              setFormSubmitted("Fail");
              return;
            }
          }
          setFormSubmitted("Submitted");
        } else {
          setFormSubmitted("Fail");
        }
      } catch (error) {
        setFormSubmitted("Fail");
      }
    }
  }

  const updateDeviation = (index) => {
    setDeviationNumber(index);
  }
  const updateCurrentPanel = (index) => {
    setCurrentPanel(index);
  }
  const updateNavigationVisiblity = (visibility) => {
    setNavigationVisiblity(visibility);
  }


  return (
    <>
      <FormContext.Provider value={{ formText, formTextNorwegian, validPolicy, allianzCase, tokenError, formError, showSpinner, currentPanel, deviationNumber, formTextEnglish, language, routes, routesPath, formData, insuredDataNorwegian, insuredDataEnglish, policyDataNorwegian, policyDataEnglish, deviationDataNorwegian, deviationDataEnglish, damageDataNorwegian, damageDataEnglish, formFiles, stage, formValid, errorText, updateCurrentPanel, updateStage, handleStages, updateLanguage, updateFormData, updateDeviation, updateNavigationVisiblity }}>
        {!isIE && formSubmitted === "Loading" && <div className="loading" aria-label="Vennligst vente" ><h4 className="normal-header padding-small">{formText["waiting"]}</h4></div>}
        {!isIE && formSubmitted === "Fail" && <Logo text="Fail" />}
        {formSubmitted === "Submitted" && <Logo text={`${formText["completed_message"]}${caseNumber}`} />}

        {(formText && formSubmitted !== "Submitted" && formSubmitted !== "Fail" && formSubmitted !== "Loading") &&
          <>
            <Header />
            <main>
              <form className="form-container" ref={formElement} onSubmit={handleSubmit} noValidate>
                <Routes >
                  <Route path="/" element={<Home />} />
                  <Route path="/forsikrede" element={<Insured />} />
                  <Route path="/meldingtype" element={<ReportUpload />} />
                  <Route path="/kjoperforsikring" element={<DamageReport />} />
                  <Route path="/dokument" element={<UploadDocument />} />
                  <Route path="/oppdatering" element={<DamageUploadReport />} />
                  <Route path="/kvittering" element={<Receipt />} />
                  <Route path="/informasjon" element={<InformationForCases />} />
                  <Route path="/informasjonAvhendingsloven" element={<InformationForProcessing />} />
                  <Route path="/*" element={<Home />} />
                </Routes >
                {navigationVisiblity && <FormNavigation />}
              </form>
            </main>
            <Footer />
          </>
        }
      </FormContext.Provider>
    </>
  );
}

export default App;
