import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FormContext } from './formContext';

export default function Header() {

    const { stage, validPolicy, routesPath, formValid, handleStages, language, updateLanguage } = useContext(FormContext);
    const checkIndex = (index) => {

        if (stage > index) {
            return true
        } else if (index === stage + 1 && formValid) {
            return true;
        } else {
            return false;
        }
    }

    const handleLanguage = (event) => {
        event.preventDefault();
        updateLanguage(event.target.value);
    }

    return (
        <header>
            <div className='logo-container'>
                <Link to="/" >
                    <img src="logo.png" className="app-logo" alt="logo" />
                </Link>
                <span className='language-container'>
                    <button className={language === 'No' ? 'language-container__active' : 'language-container__disabled'} type='button' onClick={handleLanguage} value='No' aria-label="Bytte språk til Norsk.">No</button>
                    <span aria-hidden="true">/</span>
                    <button className={language === 'En' ? 'language-container__active' : 'language-container__disabled'} type='button' onClick={handleLanguage} value='En' aria-label="Change language to english.">En</button>
                </span>
            </div>
            <nav className="main-navigation">
                <ul>
                    {routesPath && routesPath.map((route, index) => {
                        if (index === 0)
                            return (
                                <li key={index}><Link className={`active ${index === stage ? "current-active" : ""} `} to="/" onClick={handleStages} >{route.title}</Link></li>
                            )
                        return (
                            <li key={index}><Link aria-disabled={checkIndex(index) && validPolicy ? false : true} className={`${checkIndex(index) && validPolicy ? "ready" : "no-pointer-events"} ${index <= stage ? "active" : ""} ${index === stage ? "current-active" : ""} `} to={checkIndex(index) && validPolicy ? routesPath[index].path : routesPath[stage].path} onClick={handleStages}>{route.title}</Link></li>
                        )
                    })}

                </ul>
            </nav>
        </header>
    )
}
