import React, { useContext, useEffect } from 'react'
import { FormContext } from './formContext'
import RadioGroup from '../components/custom/RadioGroup';
import {  Navigate } from 'react-router-dom';


export default function ReportUpload() {
    const { formText, updateStage } = useContext(FormContext);
    const { insured: data } = formText;

    useEffect(() => {
        if(!formText) {
            updateStage(0);
            Navigate("/");
        }
        updateStage(1);
    },[])

    return (
        <>
            <RadioGroup item={{ name: "reportOrUpload", label: data["upload_report"]["label"], key: "upload_report", values: data["upload_report"]["values"], labels: data["upload_report"]["labels"], required: data["upload_report"]["required"] }} />
        </>
    )
}