import React, { useEffect, useContext } from 'react';
import { FormContext } from './formContext';
import Field from '../components/custom/Field';

export default function Insured() {

    const { formText, formData, updateStage } = useContext(FormContext);
    const { insured: data } = formText;

    useEffect(() => {
        updateStage(1);
    })

    return (
        <>
            <h3 className='medium-header'>{data["title"]}</h3>
            <Field item={{ data: data["fullname"], key: "fullname", type: "text", value: formData[data["fullname"]["name"]] }} />
            <Field item={{ data: data["address"], key: "address", type: "text", value: formData[data["address"]["name"]] }} />
            <Field item={{ data: data["place"], key: "place", type: "text", value: formData[data["place"]["name"]] }} />
            <Field item={{ data: data["email"], key: "email", type: "email", value: formData[data["email"]["name"]] }} />
            <Field item={{ data: data["phone"], key: "phone", type: "text", value: formData[data["phone"]["name"]] }} />
        </>
    )
}
