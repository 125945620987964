export default function getEmailBody(casenumber, email, companyName) {

    let emailBody;

    switch (companyName) {
        case "Gar-Bo":
            emailBody = `<div>
            <p>Hei,
            <br/><br/>
            Din sak er nå registrert med følgende saksnummer: ${casenumber}. 
            <br/><br/>
            Saken din behandles av Crawford & Company (Norway) AS på vegne av Garbo. 
            <br/><br/>
            Vennligst alltid oppgi saksnummeret når du tar kontakt med oss slik at vi kan identifisere saken din. 
            <br/><br/>
            Du har tegnet en boligkjøperforsikring. Dette er en rettshjelpsforsikring som gir deg tilgang 
            til juridisk bistand for å rette krav mot selger, dersom forholdet du reklamerer over utgjør en 
            mangel etter avhendingsloven(for informasjon om avhendingsloven, se vedlegg).
            <br/><br/>
            For at vi skal kunne vurdere saken din, må vi ha følgende dokumenter:
            </p>
            <ul>
                <li>Signert kjøpekontrakt</li>
                <li>Fullstendig salgsoppgave / prospekt(takst, egenerklæring)</li>
                <li>Overtakelsesprotokoll</li>
            </ul>
            <p>
            Har du ikke har disse dokumentene tilgjengelig elektronisk / PDF - fil, ta kontakt med <br/>
            eiendomsmegler du kjøpte av Eiendomsmegler skal kunne fremskaffe elektronisk kopi.<br/>
            <br/>
            Vi har verken anledning til å vurdere saken eller svare på konkrete spørsmål før ovennevnte <br/>
            dokumenter er mottatt.
            <br/><br/>
            Så snart all nødvendig dokumentasjon er mottatt vil saksbehandler foreta en innledende <br/>
            juridisk vurdering av saken. (Se vedlegg om innledende saksgang).<br/>
            <br/>
            Dersom vi anser det sannsynlig at forholdet du reklamerer over kan utgjøre en rettslig <br/>
            mangel etter avhendingslovens bestemmelser, vil vi be om at egenandelen på kr. 4000, -<br/>
            innbetales til oss. Egenandelen refunderes dersom du skulle vinne frem med hele eller deler<br/>
            av kravet.<br/>
            <br/>
            Dersom du ikke har hørt fra oss innen tre virkedager, ta kontakt på e - post: ${email}.<br/>
            Husk saksnummer!</p>
            </div>`;
            break;

        default:
            emailBody = `<div>
                <p>Hei,
                <br/><br/>
                Din sak er nå registrert med følgende saksnummer: ${casenumber}. 
                <br/><br/>
                Vennligst alltid oppgi saksnummeret når du tar kontakt med oss slik at vi kan identifisere saken din. 
                <br/><br/>
                Du har tegnet en boligkjøperforsikring. Dette er en rettshjelpsforsikring som gir deg tilgang <br/>
                til juridisk bistand for å rette krav mot selger, dersom forholdet du reklamerer over utgjør en <br/>
                mangel etter avhendingsloven(for informasjon om avhendingsloven, se vedlegg).
                <br/><br/>
                For at vi skal kunne vurdere saken din, må vi ha følgende dokumenter:
                </p>
                <ul>
                    <li>Signert kjøpekontrakt</li>
                    <li>Fullstendig salgsoppgave / prospekt(takst, egenerklæring)</li>
                    <li>Overtakelsesprotokoll</li>
                </ul>
                <p>
                Har du ikke har disse dokumentene tilgjengelig elektronisk / PDF - fil, ta kontakt med <br/>
                eiendomsmegler du kjøpte av Eiendomsmegler skal kunne fremskaffe elektronisk kopi.<br/>
                <br/>
                Vi har verken anledning til å vurdere saken eller svare på konkrete spørsmål før ovennevnte <br/>
                dokumenter er mottatt.
                <br/><br/>
                Så snart all nødvendig dokumentasjon er mottatt vil saksbehandler foreta en innledende <br/>
                juridisk vurdering av saken. (Se vedlegg om innledende saksgang).<br/>
                <br/>
                Dersom vi anser det sannsynlig at forholdet du reklamerer over kan utgjøre en rettslig <br/>
                mangel etter avhendingslovens bestemmelser, vil vi be om at egenandelen på kr. 4000, -<br/>
                innbetales til oss. Egenandelen refunderes dersom du skulle vinne frem med hele eller deler<br/>
                av kravet.<br/>
                <br/>
                Dersom du ikke har hørt fra oss innen tre virkedager, ta kontakt på e - post: ${email}.<br/>
                Husk saksnummer!</p>
                </div>`;
            break;
    }

    return emailBody;

}
