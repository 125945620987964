export default function norwegianSource() {
    return (
        {
            routes: [{ path: "/", title: "Meld sak" }, { path: "/forsikrede", title: "Forsikrede" }, { path: "/meldingtype", title: "Nytt avvik/last opp dokumentasjon" }, { path: "/kjoperforsikring", title: "Skadeskjema" }, { path: "/dokument", title: "Dokumenter" }, { path: "/oppdatering", title: "Din sak/dokumentasjon" }, { path: "/kvittering", title: "Send inn" }],
            waiting: "Vennligst vent...",
            waiting_policy: "validerer polisenummer",
            token_error: "Vi kunne ikke finne informasjonen i forbindelse til polisenummer og postnummer som du har sett inn.",
            completed_message: "Din sak er nå registrert med følgende saksnummer: ",
            form_fail: "Det oppsto en feil!",

            requirement: {
                title: "Her kan du melde inn din forsikringssak",
                information: "Fyll inn polisenummer og postnummer for boligen som er forsikret.",
                polisnumber: {
                    label: "Polisenummer",
                    name: "polisenummer",
                    placeholder: "",
                    required: "true",
                    error: "Dette feltet er obligatorisk (kun siffer)"
                },
                postnumber: {
                    label: "Postnummer tilhørende eiendom",
                    name: "postnummer",
                    placeholder: "",
                    required: "true",
                    error: "Dette feltet er obligatorisk (4 siffer)"
                },
                is_dnb: {
                    label: "Har du kjøpt boligen din gjennom DNB?",
                    values: ["Ja", "Nei"],
                    labels: ["Ja", "Nei"],
                    required: "",
                    error: ""
                },
                not_dnb: {
                    text: "Har du ikke kjøpt bolig gjennom DNB Eiendom, behandles ikke saken din av oss, Crawford & Company. Ta kontakt med megler eller Söderberg&Partners for informasjon om hvor du kan melde din sak.",
                    header: "Kontaktinformasjon til Söderberg&Partners:",
                    email: {
                        label: "E-post",
                        value: "boligkjoperforsikring@soderbergpartners.no"
                    },
                    tel: {
                        label: "tlf",
                        value: "930 00 020"
                    }
                }
            },
            insured: {
                title: "Forsikringstaker",
                fullname: {
                    label: "Full navn",
                    name: "fulltnavn",
                    placeholder: "Full navn",
                    required: "true",
                    error: ""
                },
                address: {
                    label: "Adresse på boligen som er forsikret",
                    name: "addresse",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                place: {
                    label: "Sted",
                    name: "sted",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                email: {
                    label: "E-post",
                    name: "epost",
                    placeholder: "",
                    required: "true",
                    error: ""
                },
                phone: {
                    label: "Telefonnummer",
                    name: "telefonnummer",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                upload_report: {
                    label: "Har du oppdaget et avvik ved boligen du ønsker å melde til oss? Velg Meld avvik. Har du allerde en sak hos oss men det gjenstår å sende inn etterspurt dokumentasjon? Velg Last opp dokumenter",
                    values: ["Meld avvik", "Send inn dokumentasjon"],
                    labels: ["Meld avvik", "Send inn dokumentasjon"],
                    required: "true",
                    error: ""
                },
            },
            information: {
                title: "Informasjon om kjøp",
                sellername: {
                    label: "Navn på selger",
                    name: "NavnPaaSelger",
                    placeholder: "Full navn",
                    error: ""
                },
                purchase_price: {
                    label: "Kjøpesum",
                    name: "Kjoepesum",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                date_for_contract: {
                    label: "Dato for kontraktsignering",
                    name: "DatoForKontraktsignering",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                date_for_takeover: {
                    label: "Dato for overtakelse",
                    name: "DatoForOvertakelse",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                bulding_area: {
                    label: "Byggeår for bolig",
                    name: "byggeaar",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                on_property_visit: {
                    label: "Var du på visning?",
                    values: ["Ja", "Nei"],
                    labels: ["Ja", "Nei"],
                    required: "",
                    error: ""
                },
                deviation_visible: {
                    label: "Var avviket/avvikene synlig på visning?",
                    values: ["Ja", "Nei"],
                    labels: ["Ja", "Nei"],
                    required: "",
                    error: ""
                },
                deviation_further_examine: {
                    label: "Foretok du ytterligere undersøkelser rundt avviket/avvikene?",
                    values: ["Ja", "Nei"],
                    labels: ["Ja", "Nei"],
                    required: "",
                    error: ""
                },
                work_with_building: {
                    label: "Har du eller andre som fulgte deg på visning byggteknisk kompetanse?",
                    values: ["Ja", "Nei"],
                    labels: ["Ja", "Nei"],
                    required: "",
                    error: ""
                },
                agent_professional_background: {
                    label: "Hvem var med deg på visning og hva er deres yrkesbakgrunn?",
                    name: "HvemVarMedPaVisningOgHvaErDeresYrkesbakgrunn",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                signed_owner_shift: {
                    label: "Tegnet selger eierskifteforsikring?",
                    values: ["Ja", "Nei"],
                    labels: ["Ja", "Nei"],
                    required: "",
                    error: ""
                },
                second_title: "Informasjon om avvik",
                deviation: {
                    header: "Hvor mange avvik vil du melde inn?",
                    information: "Velg avvik for å skrive inn/endre informasjon",
                    text: "Avvik"
                },
                damage_type: {
                    label: "Skadetype",
                    name: "skadetype",
                    initial_value: "--- Velg avvik ---",
                    values: ["Skadedyr", "Skjeggkre", "Lekkasje/fukt", "Mugg/råte", "Bad", "Vinduer", "Tak", "Konstruksjonsfeil", "Arealavvik", "Ulovligheter/påbud", "Uriktige opplysninger om kostnader/utgifter", "Elektrisk anlegg", "Utvendig/tomt", "Annet"]
                },
                deviation_description: {
                    label: "Beskriv avviket",
                    name: "BeskrivelseAvAvviket",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                deviation_information: {
                    label: "Har du snakket med selger om avviket, hvis ja, hvilke opplysninger fikk du?",
                    name: "InformasjonOmAvvikFraSelger",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                deviation_informed: {
                    label: "Har du blitt informert om avvik forut for bindende bud? Hvis ja, hvilken informasjon fikk du?",
                    name: "InformasjonFoerBindendeBud",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                when_deviation_detected: {
                    label: "Når oppdaget du avvik?",
                    name: "NaarOppdagetDuAvviket",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                how_deviation_detected: {
                    label: "Hvordan oppdaget du avviket?",
                    name: "HvordanOppdagetDuAvviket",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                self_reclaim: {
                    label: "Har du selv varslet selger eller selgeres forsikring skriftlig om avvik?",
                    values: ["Ja", "Nei"],
                    labels: ["Ja", "Nei"],
                    required: "",
                    error: ""
                },
                information_sent: {
                    label: "Når og hvilken informasjon kom frem?",
                    name: "NaarOgHvilkenInformasjonKomFremVedSelvReklamasjon",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                deviation_sale_document: {
                    label: "Er avvik omtalt i salgsdokumentasjon?",
                    name: "ErAvvikNevntIDokumentasjonen",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                incorrect_information: {
                    label: "Mener du selger har gitt mangelfulle eller uriktige opplysninger om avvik?",
                    values: ["Ja", "Nei"],
                    labels: ["Ja", "Nei"],
                    error: ""
                },
                why_mentioned: {
                    label: "I så fall, hvorfor mener du dette?",
                    name: "HvorforMenerDuSelgerHarGittUriktigInformasjon",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                assumed_repair_cost: {
                    label: "Antatte utbedringskostnader?",
                    name: "AntattUtbedringsKostnad",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                contact_house_insurance: {
                    label: "Har du vært i kontakt med din hus-/innboforsikring? Hvis ja, hva ble utfallet?",
                    name: "KontaktMedHusOgInnboforsikring",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                additional_information: {
                    label: "Eventuelle tilleggskommentarer",
                    name: "TilleggsKommentar",
                    placeholder: "",
                    required: "",
                    error: ""
                },
                attachment_title: "Nødvendig dokumentasjon",
                Other_document: {
                    label: "Dokument",
                    text: "Velg fil",
                    name: "andreDokument",
                    required: "",
                    error: "",
                },
                picture: {
                    label: "Bilde",
                    text: "Velg fil",
                    name: "bilde",
                    required: "",
                    error: "",
                },
                sales_task: {
                    label: "Salgsoppgave",
                    text: "Velg fil",
                    name: "salgsoppgave",
                    required: "",
                    error: "",
                },
                purchasing_contract: {
                    label: "Kjøpekontrakt",
                    text: "Velg fil",
                    name: "kjopekontrakt",
                    required: "",
                    error: "",
                },
                takeover_protocol: {
                    label: "Overtagelsesprotokoll",
                    text: "Velg fil",
                    name: "overtagelsesprotokoll",
                    required: "",
                    error: "",
                }
            },
            receipt: {
                title: "Kvittering",
                text_lead: "Du er nå ferdig med å registrere ditt krav, og saken er klar for å sendes inn til videre behandling.",
                subtext: "Vennligst se over at alle opplysninger er korrekte. Du hører fra oss så snart saken din er behandlet.",
                information_title: "Opplysninger om kravet",
                insured_title: "Forsikrede",
                damage_title: "Skadeskjema opplysninger",
                deviation_title: "Avvik",
                consent: "Ved å sende inn sak samtykker du til at vi kommuniserer elektronisk.",
                confirm: "Jeg bekrefter at jeg har lest gjennom salgsdokumentasjonen og at avvikene jeg melder inn ikke er nevnt i forbindelse med salget."

            },
            form_navigation: {
                next: "Neste",
                back: "Tilbake",
                submit: "Send inn",
                error: "Alle obligatoriske felt må besvares!"
            },
            footer: {
                information_1: "Informasjon om saksgangen",
                information_2: "Informasjon om Avhendingsloven",
                privacy: "Personvernerklæring",
                phone: "Tlf",
                contact_form_title: "Kontaktskjema",
                contact_form_body: "Gå til kontaktskjema",
                helpText: "For hjelp til utfylling av skjema eller andre spørsmål, vennligst ring oss mellom 0800 og 1600. Tlf: ",
            }



        }
    )
}

// if (lang === "nor") {
//     $scope.language.progressbar = ["Kravet", "Forsikrede", "Skadeskjema", "Kvittering"];
//     $scope.language.kravet = ["Her kan du melde inn din forsikringssak", "Polisenummer", "Postnummer tilhørende eiendom", 'Har du kjøpt boligen din gjennom DNB?'];
//     $scope.language.forsikrede = ["Forsikringstaker", "Fullt navn: ", "Adresse på boligen som er forsikret: ", "Sted: ",
//         "Informasjon over blir fyllt inn automatisk ved oppføring av polisenummeret og postnummeret på første side som ble registrert ved kjøp av eiendom.", "E-post*", "Telefonnummer",
//         "Skriv en gyldig epost addresse", "Telefonnummer må være minst 8 nummer"];
//     $scope.language.skadeskjema = ["Informasjon om kjøp", "Navn på selger", "Kjøpesum", "Dato for kontraktsignering",
//         "Dato for overtakelse", "Byggeår for bolig", "Var du på visning?", "ja", "nei", "Var avviket/avvikene synlig på visning?", "ja", "nei",
//         "Foretok du ytterligere undersøkelser rundt avviket/avvikene?", "ja", "nei", "Har du eller andre som fulgte deg på visning byggteknisk kompetanse?", "ja", "nei",
//         "Hvem var med deg på visning og hva er deres yrkesbakgrunn?", "Tegnet selger eierskifteforsikring?", "ja", "nei", "Informasjon om avvik",
//         "Hvor mange avvik vil du melde inn?", "Velg avvik for å skrive inn/endre informasjon", "Avvik", "Skadetype", "Beskriv avviket",
//         "Har du snakket med selger om avviket, hvis ja, hvilke opplysninger fikk du?",
//         "Har du blitt informert om avvik forut for bindende bud? Hvis ja, hvilken informasjon fikk du?", "Når oppdaget du avvik?",
//         "Hvordan oppdaget du avviket?",
//         "Har du selv varslet selger eller selgeres forsikring skriftlig om avvik?", "ja", "nei",
//         "Når og hvilken informasjon kom frem?", "Er avvik omtalt i salgsdokumentasjon?",
//         "Mener du selger har gitt mangelfulle eller uriktige opplysninger om avvik?", "ja", "nei", "I så fall, hvorfor mener du dette?",
//         "Antatte utbedringskostnader?", "Har du vært i kontakt med din hus-/innboforsikring? Hvis ja, hva ble utfallet?",
//         "Eventuelle tilleggskommentarer.", "Nødvendig dokumentasjon", "Salgsoppgave", "Kjøpekontrakt", "Overtagelsesprotokoll",
//         "", "Velg fil", "Antall avvik"];
//     $scope.language.kvittering = ["Kvittering", "Du er nå ferdig med å registrere ditt krav, og saken er klar for å sendes inn til videre behandling.",
//         "Vennligst se over at alle opplysninger er korrekte.", "Du hører fra oss så snart saken din er behandlet.",
//         "Opplysninger om kravet", "Forsikrede", "Skadeskjema opplysninger"];
//     $scope.language.skadetype = ["Skadedyr", "Skjeggkre", "Lekkasje/fukt", "Mugg/råte", "Bad", "Vinduer", "Tak", "Konstruksjonsfeil", "Arealavvik", "Ulovligheter/påbud",
//         "Uriktige opplysninger om kostnader/utgifter", "Elektrisk anlegg", "Utvendig/tomt", "Annet"];
//     $scope.language.buttons = ["Tilbake", "Neste", "Send Skadeskjema"];
// }