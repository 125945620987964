import React, { useEffect, useContext, useRef } from 'react';
import { FormContext } from './formContext';
import RadioGroup from './custom/RadioGroup';
import Field from './custom/Field';
import Deviation from './Deviation';
import FileList from '../components/custom/FileList';
import { Navigate } from 'react-router-dom';



export default function DamageReport() {

    const { formText, formFiles, formData, deviationNumber, currentPanel, updateCurrentPanel, updateFormData, updateStage, updateDeviation } = useContext(FormContext);
    const { information: data } = formText;
    const deviationNumberInput = useRef();

    useEffect(() => {
        if(!formText) {
            updateStage(0);
            Navigate("/");
        }
        updateStage(2);
    })

    const removeDeviation = () => {
        if (deviationNumber > 1) {
            updateDeviation(deviationNumber - 1);
            deviationNumberInput.current.value = deviationNumber;
            let dn = parseInt(deviationNumberInput.current.value);
            if (currentPanel >= dn) {
                updateCurrentPanel(currentPanel - 1);
            }
        }
    }

    const addDeviation = () => {
        if (deviationNumber < 6) {
            updateDeviation(deviationNumber + 1);
            deviationNumberInput.current.value = deviationNumber;
        }
    }

    const updatePanelIndex = (index) => {
        updateCurrentPanel(index);
    }

    return (
        <>
            <h2 className='large-header'>{data["title"]}</h2>
            <Field item={{ data: data["sellername"], type: "text", key: "sellername", value: formData[data["sellername"]["name"]] }} />
            <Field item={{ data: data["purchase_price"], type: "text", key: "purchase_price", value: formData[data["purchase_price"]["name"]] }} />
            <Field item={{ data: data["date_for_contract"], type: "date", key: "date_for_contract", value: formData[data["date_for_contract"]["name"]] }} />
            <Field item={{ data: data["date_for_takeover"], type: "date", key: "date_for_takeover", value: formData[data["date_for_takeover"]["name"]] }} />
            <Field item={{ data: data["bulding_area"], type: "text", key: "bulding_area", value: formData[data["bulding_area"]["name"]] }} />

            <RadioGroup item={{ name: "pavisning", label: data["on_property_visit"]["label"], key: "on_property_visit", values: data["on_property_visit"]["values"], labels: data["on_property_visit"]["labels"] }} />

            {formData && (formData["pavisning"] === "Ja") && <>
                <RadioGroup item={{ name: "AvvikSynligPaVisning", label: data["deviation_visible"]["label"], key: "deviation_visible", values: data["deviation_visible"]["values"], labels: data["deviation_visible"]["labels"] }} />
                <RadioGroup item={{ name: "YtterligereUndersokelserRundtAvvik", label: data["deviation_further_examine"]["label"], key: "deviation_further_examine", values: data["deviation_further_examine"]["values"], labels: data["deviation_further_examine"]["labels"] }} />
                <RadioGroup item={{ name: "DuEllerAndreByggtekniskKompetanse", label: data["work_with_building"]["label"], key: "work_with_building", values: data["work_with_building"]["values"], labels: data["work_with_building"]["labels"] }} />
            </>}
            {formData && (formData["DuEllerAndreByggtekniskKompetanse"] === "Ja") && <Field item={{ data: data["agent_professional_background"], type: "text", key: "agent_professional_background", value: formData[data["agent_professional_background"]["name"]] }} />}

            <RadioGroup item={{ name: "TegnetDuEierskifteforsikring", label: data["signed_owner_shift"]["label"], key: "signed_owner_shift", values: data["signed_owner_shift"]["values"], labels: data["signed_owner_shift"]["labels"] }} />

            <h3 className='medium-header'>{data["second_title"]}</h3>
            <p className='lead'>{data["deviation"]["header"]}</p>
            <div className="number-input">
                <button type="button" onClick={removeDeviation} className="minus" aria-label="Fjern siste avvik / delete last deviation"></button>
                <span>{deviationNumber}</span>
                <input ref={deviationNumberInput} type="number" className="quantity" min="1" max="6" name="hvorMangeAvvik" value={deviationNumber} onChange={(event) => { updateFormData(event, data["deviation"]["header"]) }} hidden />
                <button type="button" onClick={addDeviation} className="plus" aria-label="Legg til ny avvik / add new deviation"></button>
            </div>
            <p>{data["deviation"]["information"]}</p>

            <div className="panel-container"></div>
            <div className="panel-container__control" role="tablist">

                {[...Array(deviationNumber)].map((dn, index) => {
                    ++index;
                    return (
                        <button type='button' key={index} id={`deviation${index}`} className={`panel-container__button ${currentPanel === index ? "panel-container__button__selected selected" : ""}`} role="tab" aria-selected={currentPanel === index ? true : false} aria-controls={`deviation-panel${index}`} onClick={() => { updatePanelIndex(index) }}>{data["deviation"]["text"]} {index}</button>
                    )
                })}
                {[...Array(deviationNumber)].map((dn, index) => {
                    ++index;
                    return (<Deviation key={index} display={currentPanel === index ? true : false} header={`${data["deviation"]["text"]} ${index}`} label={`deviation${index}`} />)
                })}
            </div>

            <h3 className='medium-header'>{data["attachment_title"]}</h3>
            <FileList item={{ text: data["Other_document"]["text"], label: data["Other_document"]["label"], name: "andreDokument", multiple: true }} list={formFiles && formFiles["andreDokument"]} />

        </>
    )
}