import React, { useContext } from 'react';
import { FormContext } from '../formContext';

export default function Filed({ item, isTextarea = false }) {
    const { updateFormData, errorText } = useContext(FormContext);
    const { data } = item;
    const { name } = item;

    if (!isTextarea) {
        return (
            <div>
                <label className="input-label">
                    <span className={`${data.required === "true" ? "required" : ""}`}> {data.label}</span>
                    <input id={item.id ? item.id : ""} pattern={item.pattern ? item.pattern : ".*"} name={name ? name : data.name} value={item.value ? item.value : ""} type={item.type} min={item.type === "date" ? data.min : ""} placeholder={item.type === "date" ? "dd.mm.åååå" : data.placeholder ? data.placeholder : data.label} required={data.required === "true" ? true : false} onChange={(event) => { updateFormData(event, item.key) }} />
                </label>
                <p id={`error-message-${data.name}`} className="error-message no-display">{data.error ? data.error : errorText}</p>
            </div>
        )
    } else {
        return (
            <div>
                <label className="input-label">
                    <span className={`${item.required ? "required" : ""}`}> {data.label}</span>
                    <textarea name={name ? name : data.name} placeholder={data.placeholder ? data.placeholder : data.label} onChange={(event) => { updateFormData(event, item.key) }} value={item.value ? item.value : ""} required={data.required === "true" ? true : false}  ></textarea>
                </label>
                <p id={`error-message-${item.name}`} className="error-message no-display">{data.error ? data.error : errorText}</p>
            </div>
        )
    }
}
