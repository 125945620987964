import React, { useContext } from 'react';
import { FormContext } from './formContext';
import RadioGroup from '../components/custom/RadioGroup';
import Field from '../components/custom/Field';



export default function Deviation({ display, header, label }) {
    const identifier = `avvik${header.split(" ")[1]}-`;
    const { formText, formData, updateFormData, errorText } = useContext(FormContext);
    const data = formText["information"];
    return (
        <div className={display ? "" : "no-display"} role="tabpanel" aria-labelledby={label}>
            <h4 className='normal-header'>{header}</h4>
            <div>
                <label className="input-label">
                    <span className="required">{data["damage_type"]["label"]}</span>
                    <select id="skadetype" required name={identifier + data["damage_type"]["name"]} value={formData[identifier + data["damage_type"]["name"]] ? formData[identifier + data["damage_type"]["name"]] : ""} onChange={(event) => { updateFormData(event, "damage_type") }}>
                        <option value="">{data["damage_type"]["initial_value"]}</option>
                        {data["damage_type"]["values"].map((item, index) => {
                            return (
                                <option key={index} value={item}>{item}</option>
                            )
                        })}
                    </select>
                </label>
                <p id={`error-message-${identifier + data["damage_type"]["name"]}`} className="error-message no-display">{errorText}</p>
            </div>

            <Field item={{ data: data["deviation_description"], name: identifier + data["deviation_description"]["name"], type: "text", value: formData[identifier + data["deviation_description"]["name"]], key: "deviation_description" }} isTextarea={true} />
            <Field item={{ data: data["deviation_information"], name: identifier + data["deviation_information"]["name"], type: "text", value: formData[identifier + data["deviation_information"]["name"]], key: "deviation_information" }} />
            <Field item={{ data: data["deviation_informed"], name: identifier + data["deviation_informed"]["name"], type: "text", value: formData[identifier + data["deviation_informed"]["name"]], key: "deviation_informed" }} />
            <Field item={{ data: data["when_deviation_detected"], name: identifier + data["when_deviation_detected"]["name"], type: "date", value: formData[identifier + data["when_deviation_detected"]["name"]], key: "when_deviation_detected" }} />
            <Field item={{ data: data["how_deviation_detected"], name: identifier + data["how_deviation_detected"]["name"], type: "text", value: formData[identifier + data["how_deviation_detected"]["name"]], key: "how_deviation_detected" }} isTextarea={true} />

            <RadioGroup item={{ name: identifier + "SelvReklamertEllerGittSkriftligMelding", label: data["self_reclaim"]["label"], key: "self_reclaim", values: data["self_reclaim"]["values"], labels: data["self_reclaim"]["labels"] }} />

            {formData && (formData[identifier + "SelvReklamertEllerGittSkriftligMelding"] === "Ja") && <>
                <Field item={{ data: data["information_sent"], name: identifier + data["information_sent"]["name"], type: "text", value: formData[identifier + data["information_sent"]["name"]], key: "information_sent" }} />
            </>}

            <Field item={{ data: data["deviation_sale_document"], name: identifier + data["deviation_sale_document"]["name"], type: "text", value: formData[identifier + data["deviation_sale_document"]["name"]], key: "deviation_sale_document" }} />

            <RadioGroup item={{ name: identifier + "MeglerEllerSelgerUriktigInformasjon", label: data["incorrect_information"]["label"], key: "incorrect_information", values: data["incorrect_information"]["values"], labels: data["incorrect_information"]["labels"] }} />

            {formData && (formData[identifier + "MeglerEllerSelgerUriktigInformasjon"] === "Nei") && <>
                <Field item={{ data: data["why_mentioned"], name: identifier + data["why_mentioned"]["name"], type: "text", value: formData[identifier + data["why_mentioned"]["name"]], key: "why_mentioned" }} />
            </>}

            <Field item={{ data: data["assumed_repair_cost"], name: identifier + data["assumed_repair_cost"]["name"], type: "text", value: formData[identifier + data["assumed_repair_cost"]["name"]], key: "assumed_repair_cost" }} />
            <Field item={{ data: data["contact_house_insurance"], name: identifier + data["contact_house_insurance"]["name"], type: "text", value: formData[identifier + data["contact_house_insurance"]["name"]], key: "contact_house_insurance" }} />
            <Field item={{ data: data["additional_information"], name: identifier + data["additional_information"]["name"], type: "text", value: formData[identifier + data["additional_information"]["name"]], key: "additional_information" }} isTextarea={true} />

        </div>
    )
}
