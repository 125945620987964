import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FormContext } from './formContext';

export default function Footer() {

    const { formText } = useContext(FormContext);
    const { footer: data } = formText;

    return (
        <div className='footer-wrapper'>
            <div className='information-link'>
                <Link to="/informasjon" >{data["information_1"]}</Link><span aria-hidden="true">/</span>
                <Link to="/informasjonAvhendingsloven" >{data["information_2"]}</Link><span aria-hidden="true">/</span>
                <a href='https://www.crawco.com/legal/privacy-notice' target='blank'>{data["privacy"]}</a>
            </div>

            <footer>
                <div className="contact">
                    <p className="lead">Crawford &amp; Company (Norway) AS</p>
                    <p> Org nr 957 296 181  | <a href="https://www.crawco.no">crawco.no</a> | <span className="mobile-display-block">{data["phone"]}. <a href="tel:67552500">67 55 25 00</a></span>
                        {/* <span className="display-block"> {data["contact_form_title"]}: <a href="https://crawfordportal.no/kontakt" target='blank'> {data["contact_form_body"]}</a></span> */}
                    </p>
                    <br />
                    <span className='display-block'>{data["helpText"]} <a href="tel:67552500">67 55 25 00</a></span>
                    <br />
                    <br />
                    <p className="copyright">©2024 CRAWFORD & COMPANY (Norway)</p>
                </div>
            </footer >
        </div >
    )
}
