import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FormContext } from './formContext';

export default function FormNavigation() {

    const { formText, validPolicy, routesPath, stage, formValid, handleStages, formError } = useContext(FormContext);

    const navigationText = formText["form_navigation"];
    const [nextStage, setnextStage] = useState('');
    const [previousStage, setPreviousStage] = useState('');

    useEffect(() => {
        if (!routesPath) return;
        if (formValid && validPolicy && stage < routesPath.length - 1) {
            if (stage === 0) {
                if (validPolicy) {
                    setnextStage(routesPath[stage + 1].path);
                }
            } else {
                setnextStage(routesPath[stage + 1].path);
            }
        } else {
            setnextStage(routesPath[stage].path);
        }
        if (stage > 0) {
            setPreviousStage(routesPath[stage - 1].path);
        }

    }, [formValid, stage, validPolicy, routesPath].path)

    return (
        <>
            {formError && <p className="form-error lead" aria-live="assertive">{navigationText["error"]}</p>}
            <div className="form-navigation">
                <Link className="navigation-button" style={{ display: stage < 1 ? "none" : "block" }} onClick={handleStages} to={previousStage} id="previousButton" aria-label="Gå til forrige side. Go to previous page.">{navigationText["back"]}</Link>
                <Link className="navigation-button" style={{ display: stage === routesPath?.length - 1 ? "none" : "block" }} onClick={handleStages} to={nextStage} id="nextButton" aria-label="Gå til neste side. Go to next page.">{navigationText["next"]}</Link>
                <input type="submit" className="navigation-button" style={{ display: stage === routesPath?.length - 1 ? "block" : "none" }} value={navigationText["submit"]} />
            </div>
        </>
    )
}
