import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FormContext } from './formContext';

export default function InformationForProcessing() {

    const { updateNavigationVisiblity, stage, routes } = useContext(FormContext);
    const link = routes[stage].path

    useEffect(() => {
        updateNavigationVisiblity(false)
        document.body.scrollTop = document.documentElement.scrollTop = 0
    }, [])

    return (
        <div>

            <h3 className='medium-header'>Generell informasjon om Avhendingsloven</h3>

            <h4 className='normal-header'>Reklamasjonsfrist etter avhl. § 4-19</h4>

            <p>
                Kjøper må reklamere til selger/ boligselgerforsikringselskapet senest innen 2-3 måneder etter at avvikene ble eller burde ha blitt oppdaget, jf. avhl. § 4-19. For sen reklamasjon kan føre til at du som kjøper taper et eventuelt krav.
            </p>

            <h4 className='normal-header'>Selger har en utbedringsrett etter avhl.§ 4-10</h4>

            <p>
                Selger har i utgangspunktet rett til å utbedre de påberopte avvik/ feil for egen regning.
                Utbedrer du avvikene/ feilen før selger/boligselgerforsikringselskapet får anledning til å ta
                stilling til denne retten, risikerer du som kjøper å tape et krav.
                Som kjøper plikter man samtidig å forsøke å forhindre at det skal oppstå ytterligere skadeomfang.
            </p>

            <h4 className='normal-header'>Ved ditt/deres kjøp tok selger et såkalt "som den er" - forbehold.</h4>

            <p>
                De aller fleste brukte boliger i Norge selges med et slikt forbehold. Dette innebærer kort fortalt at risikoen
                for avvik/feil som utgangspunkt er overført fra selger til deg som kjøper. Dette har du/dere som kjøper(-e)
                akseptert ved signering av kjøpekontrakten. Risikoen for boligen går over fra selger til kjøper når boligen blir overlevert.
            </p>

            <p className='lead'>
                Fra dette utgangspunktet, har lovgiver oppstilt tre unntak;
            </p>

            <ul>
                <li>Selger har gitt mangelfull informasjon, og informasjonen kan anses for å ha ville virket inn på avtalen, jf. avhl § 3-7</li>
                <li>Dersom det er gitt objektivt uriktig opplysninger, og informajsonen kan anses for å ha ville virket inn på avtalen, jf. avhl. § 3-8</li>
            </ul>

            <p>
                For å holde selger ansvarlig for tilbakeholdte eller uriktige opplysninger, må
                opplysningene være så alvorlige at det ville virket inn på kjøpsavtalen hvis fullstendige
                og korrekte opplysninger ble gitt i forkant av avtaleinngåelse. En opplysning har virket inn
                på avtalen i tilfeller hvor en fullstendig og korrekt opplysning ville medført at kjøpsavtalen
                ikke ville blitt inngått på samme vilkår.
            </p>

            <ul>
                <li>Dersom boligen er i vesentlig dårligere stand enn hva du som kjøper berettiget kunne forvente ut i fra kjøpesum og forholdene forøvrig, jf. avhl. § 3-9 2. pkt.</li>
            </ul>

            <p>
                Om boligen er i vesentlig dårligere stand beror på en helhetsvurdering. Vurderingen vil gjøres på
                bakgrunn av ulike momenter, herunder blant annet:
            </p>

            <ul>
                <li>Markedsføring</li>
                <li>Salgssum</li>
                <li>Alder</li>
                <li>Risikoopplysninger</li>
                <li>Takst</li>
                <li>Evt. fagkyndig vurdering</li>
                <li>Utbedringskostnader</li>
                <li>Skadens karakter og omfang</li>
            </ul>

            <p>
                Dette er ikke en uttømmende liste. Vurderingsgrunnlaget vil variere fra sak til sak.
            </p>

            <p className='lead'>
                Forhold som ikke vil utgjøre en mangel etter avhendingsloven:
            </p>

            <ul class="dottedlist">
                <li>Det er helt eller delvis opplyst om forholdene i salgsdokumentene, jf. avhl. § 3-1.</li>
                <li>Mangelen var synlig, slik at du kunne ha sett den på visning, jf. avhl. § 3-10.</li>
                <li>Feil og mangler som avdekkes på bygningsdeler hvor alder og/ eller opplysninger tilsier at det var påregnelig med oppgradering og vedlikehold.</li>
            </ul>

            <Link className='link-back' to={link} >Tilbake til webskjema</Link>

        </div>
    )
}
