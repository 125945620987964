import React, { useRef, useContext } from 'react';
import { FormContext } from '../formContext';

export default function RadioGroup({ item, handleClick }) {
    const { updateFormData, formData, errorText } = useContext(FormContext);
    // const [state, setstate] = useState();
    const element = useRef();
    const handleState = (event) => {
        // setstate(event.target.value);
        if (handleClick) {
            handleClick(event.target.value);
        }
    }


    return (
        <>
            {item && <div className={`${item.show === "false" ? "no-display" : ""} ${item.name}`} ref={element}>
                <p className={`question-header ${item.required === true ? "required" : ""}`}>{item.label}</p>
                {item.values.map((data, index) => {
                    return (
                        <label key={index} className="radio-label">
                            <input name={item.name} type="radio" required={index === 0 && item.required ? true : false} value={data} onChange={(event) => { updateFormData(event, item.key) }} checked={formData[item.name] === data ? true : false} onClick={handleState} />
                            <span className="radio-symbol">{item.labels ? item.labels[index] : data}</span>
                        </label>
                    )
                })}
                <p id={`error-message-${item.name}`} className="error-message no-display">{item.error ? item.error : errorText}</p>

            </div>
            }
        </>
    )
}
